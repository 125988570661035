import React from "react"
import { graphql, navigate } from "gatsby"
import { Container } from "reactstrap"
import SEO from "../components/seo"
import Slider from "../components/slider-courses"
import Testimonials from "../components/testimonials"
import Partners from "../components/partners"
import { Box } from "grommet"
import ReactMarkdown from "react-markdown"
import MorePosts from "../components/blog/MorePosts/MorePosts"
import Subscription from "../components/home/subscriptionSection/Subscription"
import ButtonOutline from "../components/common/custom-button/ButtonOutline"

export const query = graphql`
  query singleBlogQuery($slug: String!) {
    contentfulAiaBlog(slug: { eq: $slug }) {
      id
      slug
      title
      description
      body {
        body
      }
    }
  }
`

const BlogPost = ({ data }) => {
  const { title, description, body } =
    data.contentfulAiaBlog

  const renderBlogSection = () => (
    <Box direction="row" gap="large">
      <Box>
        <ReactMarkdown
          children={body.body}
          components={{
            img: ({ node }) => {
              const { src, alt } = node.properties
              return (
                <img
                  alt={alt}
                  src={src}
                  style={{ maxWidth: "100%" }}
                />
              )
            },
          }}
        >
          {body.body}
        </ReactMarkdown>
      </Box>
    </Box>
  )
  const handleGoback = () => {
    navigate(-1)
  }

  return (
    <>
      <SEO title={title} description={description} />
      {/* <Slider title={title} description={description} /> */}

      <div className="container">
        <ButtonOutline
          handleClick={handleGoback}
          text="Go Back"
          withBackIcon
        />

        <Container id="Container" className="py-4">
          {renderBlogSection()}
        </Container>

        <MorePosts />
      </div>
      <Subscription />

      {/* <Partners />
      <Testimonials /> */}
    </>
  )
}

export default BlogPost
