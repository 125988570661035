import styled from "styled-components"
export const BlogCover = styled.div`
  cursor: pointer;
  width: calc(100% / 3 - 20px);
  margin-bottom: 40px;
  margin-right: 20px;
  &:nth-child(3n) {
    margin-right: 0;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }
`

export const BogListBlogContent = styled.div`
  h6 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: #777777;
  }
  h2 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
    text-transform: capitalize;
    color: #000000;
    max-width: 362px;
    @medi (max-width:767px) {
      max-width: 100%;
    }
  }
  p {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #555555;
  }
`
