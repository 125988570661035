import styled from "styled-components"

export const BlogContainer = styled.div`
  padding-top: 70px;
  @media (max-width: 480px) {
    padding-top: 40px;
  }
`

export const BlogTitle = styled.h2`
  padding-bottom: 40px;
  text-transform: capitalize;
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  @media (max-width: 480px) {
    font-size: 30px;
    padding-bottom: 30px;
  }
`
export const BlogTop = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const BlogTopLeft = styled.div`
  width: 50%;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    width: 100%;
  }
`
export const BlogTopRight = styled.div`
  width: 50%;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    padding-left: 15px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding-left: 0px;
  }
  @media (max-width: 480px) {
    margin-bottom: 0px;
  }
`
export const BlogImgCover = styled.div`
  margin-bottom: 30px;
  max-width: 560px;
  width: 100%;
  img {
    width: 100%;
  }
`
export const BlogContent = styled.div`
  h6 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
    color: #777777;
    margin-bottom: 10px;
  }

  h2 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 37px;
    text-transform: capitalize;
    color: #000000;
    max-width: 552px;
    margin-bottom: 15px;
    @media (max-width: 480px) {
      font-size: 22px;
      line-height: 29px;
    }
  }

  p {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #555555;
    max-width: 560px;
    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 25px;
    }
  }
`

export const BlogSideContent = styled.div`
  h6 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-transform: capitalize;
    color: #777777;
    @media (max-width: 480px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  h2 {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-transform: capitalize;
    color: #000000;
    max-width: 270px;
    @media (max-width: 480px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
`

export const BlogLink = styled.div`
  margin-top: 15px;
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  color: #003591;
  text-align: left;

  a {
    display: flex;
    align-items: center;
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
    color: #003591;
    transition: 200ms all ease-in-out;

    span {
      display: flex;
      margin-left: 16px;
    }
    &:hover {
      opacity: 0.7;
      transition: 200ms all ease-in-out;
    }
    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
`
export const BlogList = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  @media (max-width: 1024px) {
    align-items: flex-start;
  }
`
export const BlogListImgCover = styled.div`
  margin-right: 20px;
  max-width: 266px;
  width: 100%;
`
