import React from "react"
import Blog from "../../common/blog-card/Blog"
import {
  BlogsListCover,
  BlogsSliderContainer,
} from "../blogs-list/styles"
import { BlogTitle } from "../latestBlog/styles"
import { BlogListContainer, BlogListCover } from "./styles"
import { useMediaQuery } from "react-responsive"
import CarouselSlider from "../../slider/Slider"

const MorePosts = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  })

  return (
    <BlogListContainer>
      <BlogListCover className="container">
        <BlogTitle>More posts from this Author</BlogTitle>

        {isMobile ? (
          <BlogsSliderContainer>
            <CarouselSlider
              slidesToShow={1}
              slidesToScroll={1}
              showSlideDots={false}
            >
              <Blog />
              <Blog />
              <Blog />
              <Blog />
              <Blog />
              <Blog />
            </CarouselSlider>
          </BlogsSliderContainer>
        ) : (
          <BlogsListCover>
            <Blog />
            <Blog />
            <Blog />
          </BlogsListCover>
        )}
      </BlogListCover>
    </BlogListContainer>
  )
}

export default MorePosts
