import React from "react"
import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Button from "../components/buttons/btn"

import "../../node_modules/slick-carousel/slick/slick.css"

const SliderContainer = styled.div`
  background: #007bff;
  color: white;
  padding: 10rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
`

let SlickSlider = ({ title, description }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <SliderContainer>
      <Slider {...settings}>
        <div>
          <div className="text-center">
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
        </div>
      </Slider>
    </SliderContainer>
  )
}

export default SlickSlider
