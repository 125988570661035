import styled from "styled-components"

export const BlogsListContainer = styled.div`
  padding-top: 70px;
`
export const BlogsListCover = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 200px;
  @media (max-width: 1024px) {
    margin-bottom: 100px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

export const BlogsSliderContainer = styled.div`
    .nextBtnCover {
      @media (max-width: 767px) {
        transform: translateY(-42px) !important;
        right: -5px;
        top: 45%;
      }
    }
    .prevBtnCover {
      @media (max-width: 767px) {
        transform: translateY(-42px) !important;
        left: -5px;
        top: 45%;
      }
    }
  }
`
