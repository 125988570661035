import styled from "styled-components"

export const FormContainer = styled.div`
  background: #ffffff;
  padding: 45px 33px 130px 0px;
  max-width: 100%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
  @media (max-width: 991px) {
    padding-bottom: 50px;
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
    margin-top: 30px;
    max-width: 100%;
    padding: 15px;
  }
  @media (max-width: 374px) {
    padding: 0px;
  }
`
export const FormTitle = styled.h2`
  margin-bottom: 30px;
  max-width: 837px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 48px;
  text-transform: capitalize;
  color: #060d18;
  @media (max-width: 767px) {
    font-size: 29px;
    line-height: 38px;
  }
  @media (max-width: 480px) {
    font-size: 24px;
    line-height: 31px;
  }
`
export const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const FormFieldCover = styled.div`
  margin-bottom: 28px;
  width: 50%;

  &:nth-child(3)
  {
    width: 100%;
    }
    
    &:nth-child(5) {
      width: 300px;
      margin: 0 auto;
    }
  &:nth-child(4){
    width: 100%;
    text-align: center;
  }

  label {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #000000;
    display: block;
    margin-bottom: 3px;
  }
 
  span {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    margin-left: 14px;
    @media(max-width:480px){
      width: 90%;
      display: inline-block;
    }
    @media(max-width:374px){
      width: 85%;
      display: inline-block;
    }
  }

  p {
      font-family: 'Roboto Slab';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #555555;
      margin-top: 3px;
      margin-left: 10px;
  }  
  @media(max-width:480px){
    input[type="checkbox"] {
      width: 5%;
      height: 48px;
    }
  }
  }
@media(max-width:991px){
  width: 48%;
}
@media(max-width:767px){
  width: 100%;
}
`
export const FormFieldTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

export const SubmitButton = styled.input`
  height: 61px;
  background: #003591;
  border-radius: 100px;
  border: none;
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #ffffff;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  max-width: 390px;
  width: 100%;
  margin: 0 auto;

  &:hover {
    background: #fbda05;
    color: #003591;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`
export const InputField = styled.input`
  border: 2px solid rgba(0, 53, 145, 0.3);
  border-radius: 4px;
  max-width: 450px;
  width: 100%;
  height: 50px;
  padding-left: 12px;
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #777777;
  @media (max-width: 1169px) {
    max-width: 420px;
  }
`

export const ButtonWithIcon = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 40px;
  border: 2px solid #003591;
  background: transparent;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  span {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
    color: #003591;
    margin-left: 17px;
  }
  &:hover {
    background: #fbda05;
    border-color: #fbda05;
  }
`
