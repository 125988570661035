import React from "react"
import { Col, Row, Container } from "reactstrap"
import { FaStar } from "react-icons/fa"
import styled from "styled-components"
import miscl from "../data/miscl.json"

let Background = styled.div`
  background: linear-gradient(
    to bottom,
    #f9fbfd 0,
    #fff 100%
  );
`

let Testimonial = ({
  stars = 5,
  content,
  person,
  role,
}) => (
  <Col className="mb-4" md={4}>
    {[...Array(stars)].map((star, i) => (
      <FaStar className="mr-1" color="#ec9a3c" key={i} />
    ))}
    <p className="mt-3">{content}</p>
    <h6 className="font-weight-bold mb-0">{person}</h6>
    <small className="text-secondary">{role}</small>
  </Col>
)

export default () => {
  const testimonials = miscl.testimonial
  return (
    <Background>
      <Container className="py-5">
        <h3 className="text-center mb-5">Testimonials</h3>
        <Row>
          {testimonials?.map((testimonial, i) => (
            <Testimonial
              content={testimonial.content}
              person={testimonial.person}
              role={testimonial.role}
              key={i}
            />
          ))}
        </Row>
      </Container>
    </Background>
  )
}
