import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ButtonWithIcon } from "../../product-detail/register/styles"

const ButtonOutline = (props) => {
  const { handleClick, text, withBackIcon } = props
  return (
    <ButtonWithIcon onClick={handleClick}>
      {withBackIcon && (
        <StaticImage
          src="../../../assets/images/go-back-arrow.png"
          alt=""
        />
      )}

      <span>{text}</span>
    </ButtonWithIcon>
  )
}

export default ButtonOutline
