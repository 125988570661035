import React from "react"
import {
  BlogImgCover,
  BlogLink,
} from "../../blog/latestBlog/styles"
import { StaticImage } from "gatsby-plugin-image"
import { BlogCover, BogListBlogContent } from "./styles"
import { Link as GatsbyLink } from "gatsby"
import { graphql, useStaticQuery, navigate } from "gatsby"
import Img from "gatsby-image"

const Blog = () => {
  const handleredirect = () => {
    navigate("/blog/revit-skillsfuture-funding")
  }

  const data = useStaticQuery(graphql`
    query {
      blog: file(relativePath: { eq: "blog-image.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BlogCover onClick={handleredirect}>
      <BlogImgCover>
        <Img fluid={data.blog.childImageSharp.fluid} />
      </BlogImgCover>
      <BogListBlogContent>
        <h6>11th January 2022</h6>
        <h2>How to Apply for Absentee Payroll Funding</h2>
        <p>
          We go through the key steps required for employers
          to apply for skillsfuture absentee payroll funding
          via the enterprisejobskills website.
        </p>
        <BlogLink>
          <GatsbyLink to="/blog/revit-skillsfuture-funding">
            Read post
            <span>
              <StaticImage
                src="../../../assets/images/link.png"
                alt=""
              />
            </span>
          </GatsbyLink>
        </BlogLink>
      </BogListBlogContent>
    </BlogCover>
  )
}

export default Blog
