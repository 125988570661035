import React, { useContext } from "react"
import { Container, Col, Row } from "reactstrap"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Button from "../components/buttons/btn"
import {
  Box,
  ResponsiveContext,
  Carousel,
  Heading,
} from "grommet"

let StyledPartner = styled(Link)`
  text-text-decoration: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition-duration: 0.25s;

  &:hover {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }
`

let StyledCol = styled(Col)`
  padding: 0 2em 2em 2em;

  @mobile only screen and (max-width: 600px) {
    max-height: 200px
  }
    
  }
`

const Partners = () => {
  const { allContentfulCategory } = useStaticQuery(
    graphql`
      query {
        allContentfulCategory {
          nodes {
            id
            name
            icon {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    `
  )

  const partners = allContentfulCategory.nodes

  const size = useContext(ResponsiveContext)

  function renderPartners(partner, i) {
    if (size !== "small") {
      return (
        <Row>
          {partners?.map((partner, i) => (
            <StyledCol key={i}>
              <Img
                fluid={partner?.icon.fluid}
                alt={partner?.name}
              />
            </StyledCol>
          ))}
        </Row>
      )
    }
    return (
      <Carousel>
        {partners?.map((partner, i) => (
          <Img
            fluid={partner?.icon.fluid}
            alt={partner?.name}
          />
        ))}
      </Carousel>
    )
  }

  return (
    <Container className="py-5">
      <Heading as="h4" size="xxsmall" textAlign="center">
        Our Training Partners
      </Heading>
      {renderPartners()}
      <Row
        style={{
          justifyContent: "center",
        }}
      >
        <Button to="/courses">More Courses</Button>
      </Row>
    </Container>
  )
}

export default Partners
